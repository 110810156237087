import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import AuthService from '../Services/Auth';
import Context from '../Context';
import { axiosErrorHandler, checkIsLogin } from '../Utils/Service';
import { useNavigate } from 'react-router-dom';
import { newLoginAction } from '../Utils/Action';


export default function SignInPage() {
  const { isLogin, loginErrorMessage, loginRedirectToPrevious, dispatch } = React.useContext(Context);
  let navigate = useNavigate();

  // Prevent loading sign in page and redirect to main page once logged in
  React.useEffect(() => {
    if (isLogin) {
      if (loginRedirectToPrevious) {
        navigate(-1);
      } else {
        navigate("/");
      }
    } else if (checkIsLogin()) {
      dispatch(newLoginAction());
    }
  }, [isLogin, loginRedirectToPrevious, dispatch, navigate]);

  const handleSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Send login request to server
    const login = async () => {
      const data = new FormData(event.currentTarget);
      try {
        await AuthService.login(
          data.get('username')?.toString()!,
          data.get('password')?.toString()!
        );
        dispatch(newLoginAction());
      } catch (error) {
        axiosErrorHandler(error, dispatch);
      }
    };
    login();
  }, [dispatch]);

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {loginErrorMessage && (
        <Typography component="h1" variant="h5" color="error">
          {loginErrorMessage}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
      </Box>
    </Box>
  );
}