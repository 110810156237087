import { SIMULATION_END_DATE, SIMULATION_START_DATE } from "./Constants";
import Decimal from "decimal.js"

interface StringNumberPair {
  key: string;
  value: number;
};

interface CheckSymbol {
  is_valid: boolean;
}

interface MarketDataItem {
  symbol: string;
  shortName: string;
  sector: string;
  industry: string;
  logo_url?: string;
}

interface MarketDataInfo {
  data: MarketDataItem[];
}

interface Message {
  msg: string;
}

const sides = ["BUY", "SELL"] as const;
type Side = typeof sides[number];

interface Order {
  Date: string;
  Symbol: string;
  Order: Side;
  Shares: number;
}

interface Simulation {
  start_date: string;
  end_date: string;
  start_value: number;
  commission: number;
  impact: number;
  orders?: Array<Order>;
  allocation?: Map<string, number>;
}

interface OptimizePortfolioParam {
  start_date: string;
  end_date: string;
  mode: string;
  symbols?: string[];
  core?: string[];
  other?: string[];
}

interface PortfolioValue {
  Date: string;
  PortVals: number;
}

interface PortfolioStats {
  port_val: number;
  sharpe_ratio: number;
  volatility: number;
  returns: number;
  mdd: number;
  data: Array<PortfolioValue>;
  orders: Array<Order>;
}

interface SimulationResult {
  start_date: string;
  end_date: string;
  strategy: PortfolioStats;
  benchmark: PortfolioStats;
}

export type {
  StringNumberPair,
  CheckSymbol,
  MarketDataItem,
  MarketDataInfo,
  Message,
  Order,
  Simulation,
  OptimizePortfolioParam,
  SimulationResult,
  PortfolioStats,
};

// Convert the Partial Simulation object to Complete version
export function completeSimulationParam(obj: Partial<Simulation>): Simulation {
  const completeSimulation: Simulation = Object.assign({
    start_date: SIMULATION_START_DATE,
    end_date: SIMULATION_END_DATE,
    start_value: 100000,
    commission: 0.0,
    impact: 0.0,
    orders: undefined,
    allocation: undefined,
  }, obj);
  return completeSimulation;
}

export function isSide(maybeSide: string): boolean {
  const side = sides.find((validName) => validName === maybeSide);
  if (side) {
    // `side` comes from the list of `sides` so the compiler is happy.
    return true;
  }
  return false;
}

export function notValidNumber(n: number) {
  return n === -99999 || isNaN(n);
}

// Reference: https://stackoverflow.com/questions/40152070/javascript-using-tolocalestring-tofixed
export function formatMoney(n: number) {
  if (notValidNumber(n)) {
    return 'NaN';
  } else {
    return "$ " + (Math.round(n * 100) / 100).toLocaleString(undefined,
      {'minimumFractionDigits':2,'maximumFractionDigits':2});
  }
}

export function formatNumber(n: number, decimal: number = 2, suffix: string = '') {
  if (notValidNumber(n)) {
    return 'NaN';
  } else {
    return n.toFixed(decimal) + suffix;
  }
}

export function validateAllocation(allocation: Map<string, number>) {
  const arr = Array.from(allocation).map(([_, percent]) => new Decimal(percent));
  const sum = arr.reduce(
    (previousValue, currentValue) => previousValue.plus(currentValue),
    new Decimal(0)
  );
  return sum.equals(new Decimal(1));
}

export function validatePercent(value: number) {
  const percent = new Decimal(value);
  if (percent.lessThanOrEqualTo(new Decimal(1)) && percent.greaterThanOrEqualTo(new Decimal(0))) {
    return true;
  }
  return false;
};