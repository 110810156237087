import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Title from './Title';
import { formatMoney, SimulationResult } from '../Utils/Data';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

function provideData(data: SimulationResult) {
  return data.strategy.data.map((row, idx) => {
    return {
      Date: row.Date,
      Strategy: row.PortVals,
      Benchmark: data.benchmark.data[idx].PortVals
    };
  });
}

function moneyTickFormatter(value: number) {
  return formatMoney(value);
}

const calculateYAxisWidth = (data: SimulationResult) => {
  const strategy = Math.floor(data.strategy.data[data.strategy.data.length -1].PortVals);
  const benchmark = Math.floor(data.benchmark.data[data.benchmark.data.length -1].PortVals);
  const maxVal = Math.max(strategy, benchmark);
  const digit = maxVal.toString().length;
  return (digit + 2) * 10;
};

export default function PortValChart({ data }: { data: SimulationResult }) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>PortVals Chart</Title>
      <ResponsiveContainer>
        <LineChart
          data={provideData(data)}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="Date"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            angle={-45}
            textAnchor="end"
            height={70}
          />
          <YAxis
            padding={{ bottom: 20 }}
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={['dataMin', 'dataMax']}
            tickFormatter={moneyTickFormatter}
            width={calculateYAxisWidth(data)}
          />
          <Tooltip />
          <Legend verticalAlign="top" />
          <CartesianGrid strokeDasharray="5 5" />
          <Line
            type="monotone"
            dataKey="Strategy"
            stroke={theme.palette.primary.main}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="Benchmark"
            stroke={theme.palette.secondary.main}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}