import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Context from '../Context';
import { SimulationFormProps } from './SimulationPage';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { completeSimulationParam, OptimizePortfolioParam, Order, validateAllocation, validatePercent } from '../Utils/Data';
import simulationService from '../Services/Simulation';
import { axiosErrorHandler } from '../Utils/Service';
import OrdersTable from '../Components/OrdersTable';
import Stack from '@mui/material/Stack';
import SplitButton from '../Components/SplitButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CoreOtherPortfolioTransferList from './CoreOtherPortfolioTransferList';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';


interface OptimizePortfolioDialogProps {
  symbols: string[];
  openDialog: boolean;
  withCoreOther: boolean;
  setOpenDialog: (open: boolean) => void;
  handleOptimize: (mode: string) => () => void;
  handleOptimizeCoreOther: (mode: string) => (core: readonly string[], other: readonly string[]) => void;
}

function OptimizePortfolioDialog(props: OptimizePortfolioDialogProps) {
  const {symbols, openDialog, withCoreOther, setOpenDialog, handleOptimize, handleOptimizeCoreOther} = props;
  const [optimizeMode, setOptimizeMode] = React.useState<string>("sharpe_ratio");
  const [core, setCore] = React.useState<readonly string[]>([]);
  const [other, setOther] = React.useState<readonly string[]>([]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onPortfolioChange = (coreSymbols: readonly string[], otherSymbols: readonly string[]) => {
    setCore(coreSymbols);
    setOther(otherSymbols);
  };

  const handleOptimizeButtonClick = React.useCallback(() => {
    if (withCoreOther) {
      handleOptimizeCoreOther(optimizeMode)(core, other);
    } else {
      handleOptimize(optimizeMode)();
    }
    setOpenDialog(false);
  }, [core, other, optimizeMode, withCoreOther, handleOptimize, handleOptimizeCoreOther, setOpenDialog]);

  const disableOptimizeButton = () => {
    if (withCoreOther) {
      return core.length === 0 || other.length === 0
    } else {
      return false;
    }
  };

  const handleOptimizeModeChange = (event: SelectChangeEvent) => {
    setOptimizeMode(event.target.value as string);
  };

  return (
    <React.Fragment>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Portfolio Optimization Parameters</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 5, marginBottom: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="optimize-mode-select-label">Optimize Mode</InputLabel>
              <Select
                labelId="optimize-mode-select-label"
                id="optimize-mode-select"
                value={optimizeMode}
                label="Optimize Mode"
                onChange={handleOptimizeModeChange}
              >
                <MenuItem value={"sharpe_ratio"}>Sharpe Ratio</MenuItem>
                <MenuItem value={"volatility"}>Volatility</MenuItem>
              </Select>
            </FormControl>
          </div>
          {withCoreOther && (
            <>
              <DialogContentText>
                Please move symbols to core or other portfolio
              </DialogContentText>
              <CoreOtherPortfolioTransferList
                symbols={symbols}
                onPortfolioChange={onPortfolioChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={disableOptimizeButton()}
            variant="contained"
            onClick={handleOptimizeButtonClick}
          >
            Optimize
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const ALLOC_ALLOW_DECIMAL_SCALE = 5;

export default function SymbolAllocationForm({ simulation, onChange }: SimulationFormProps) {
  const [symbol, setSymbol] = React.useState<string | null>(null);
  const [symbolAlloc, setSymbolAlloc] = React.useState(0);
  const [previewOrders, setPreviewOrders] = React.useState<Order[]>([]);
  const [disablePreview, setDisablePreview] = React.useState(true);
  const [openCoreOtherDialog, setOpenOptimizeDialog] = React.useState(false);
  const [withCoreOther, setWithCoreOther] = React.useState(false);
  const { marketDataInfo, dispatch } = React.useContext(Context);

  // handle symbol change
  const handleChange = (e: React.SyntheticEvent, value: string | null) => {
    setSymbol(value!);
  };

  // handle symbol allocation change
  const handleValueChange = (values: NumberFormatValues) => {
    setSymbolAlloc(Number(values.value));
  };

  // handle add symbol and allocation to portfolio
  const handleAdd = () => {
    if (symbol) {
      const newMap = new Map(simulation.allocation!);
      newMap.set(symbol, symbolAlloc);
      onChange({ allocation: newMap });
    }
  };

  // handle remove symbol from allocation
  const handleRemove = (ticker: string) => () => {
    const newMap = new Map(simulation.allocation!);
    newMap.delete(ticker);
    onChange({ allocation: newMap });
  };

  // handle preview allocation
  const handlePreview = React.useCallback(async () => {
    try {
      const param = completeSimulationParam(simulation);
      const result = await simulationService.previewAllocation(param);
      setPreviewOrders(result.data);
    } catch (error) {
      axiosErrorHandler(error, dispatch);
    }
  }, [simulation, dispatch]);

  const handleOptimizeButtonClick = (selectedIndex: number) => () => {
    setWithCoreOther(selectedIndex > 0);
    setOpenOptimizeDialog(true);
  };

  const sendOptimizeRequest = React.useCallback(async (param: OptimizePortfolioParam ) => {
    try {
      const result = await simulationService.optimizePortfolio(param);
      // populate the new allocation
      const newAlloc = new Map<string, number>();
      result.data.forEach((p, _) => {
        // Filter out 0 percent symbol
        if (p.value > 0) {
          newAlloc.set(p.key, p.value);
        }
      });
      onChange({ allocation: newAlloc });
    } catch (error) {
      axiosErrorHandler(error, dispatch);
    }
  }, [dispatch, onChange]);

  const handleOptimize = React.useCallback((mode: string) => async () => {
    const symbolList = Array.from(simulation.allocation!.keys());
    const param: OptimizePortfolioParam = {
      start_date: simulation.start_date!,
      end_date: simulation.end_date!,
      mode: mode,
      symbols: symbolList,
    };
    await sendOptimizeRequest(param);
  }, [sendOptimizeRequest, simulation]);

  const handleOptimizeCoreOther = React.useCallback((mode: string) => async (core: readonly string[], other: readonly string[]) => {
    const symbolList = Array.from(simulation.allocation!.keys());
    const param: OptimizePortfolioParam = {
      start_date: simulation.start_date!,
      end_date: simulation.end_date!,
      mode: mode,
      symbols: symbolList,
      core: Array.from(core),
      other: Array.from(other),
    };
    await sendOptimizeRequest(param);
  }, [sendOptimizeRequest, simulation]);

  const isAllowPercent = (values: NumberFormatValues) => {
    return validatePercent(Number(values.value));
  };

  React.useEffect(() => {
    if (simulation.allocation) {
      const valid = validateAllocation(simulation.allocation);
      setDisablePreview(!valid);
      if (!valid) {
        setPreviewOrders([]);
      }
    }
  }, [simulation]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">
                Construct a portfolio with symbols and allocations
              </Typography>
              <Typography variant="subtitle1">
                The protfolio will rebalance every season using the following formula:
              </Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                portfolio_value = SUM_ALL_SYMBOLS(shares * previous_close)
              </Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }} gutterBottom>
                new_shares = ROUNDDOWN(portfolio_value * symbol_allocation / current_close)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            autoHighlight
            autoSelect
            id="portfolio-symbol"
            options={marketDataInfo.map(md => md.symbol)}
            renderInput={(params) => <TextField {...params} label="Symbol" helperText="Market data symbol for portfolio" />}
            onChange={handleChange}
            value={symbol}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <NumberFormat
            required
            id="allocation"
            name="allocation"
            label="Allocation"
            fullWidth
            isNumericString
            decimalScale={ALLOC_ALLOW_DECIMAL_SCALE}
            allowNegative={false}
            isAllowed={isAllowPercent}
            customInput={TextField}
            helperText="Portfolio allocation in percent (0.1 = 10%)"
            onValueChange={handleValueChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            disabled={symbol == null || symbolAlloc === 0}
            variant="contained"
            onClick={handleAdd}
          >
            Add
          </Button>
        </Grid>
        {simulation.allocation && Array.from(simulation.allocation).length > 0 && (<Grid item xs={12}>
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Symbol</TableCell>
                  <TableCell align="left">Allocation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(simulation.allocation).map(([ticker, percent]) => (
                  <TableRow
                    key={"row-" + ticker}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th">
                      <IconButton aria-label="delete" onClick={handleRemove(ticker)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{ticker}</TableCell>
                    <TableCell align="left">{percent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>)}
        {/* Preview allocation orders */}
        {simulation.allocation && Array.from(simulation.allocation).length > 0 && (
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <SplitButton
                options={[
                  "Optimize Portfolio", "Optimize Core/Other Portfolio",
                ]}
                handleClick={handleOptimizeButtonClick}
                disabled={simulation.allocation.size < 2}
              />
              <OptimizePortfolioDialog
                symbols={Array.from(simulation.allocation.keys())}
                openDialog={openCoreOtherDialog}
                setOpenDialog={setOpenOptimizeDialog}
                withCoreOther={withCoreOther}
                handleOptimize={handleOptimize}
                handleOptimizeCoreOther={handleOptimizeCoreOther}              
              />
              <Button
                disabled={disablePreview}
                variant="contained"
                onClick={handlePreview}
              >
                Preview Orders
              </Button>
            </Stack>
          </Grid>
        )}
        {simulation.allocation && Array.from(simulation.allocation).length > 0 && previewOrders.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">
                Preview the generated orders
              </Typography>
              <Typography variant="subtitle1">
                The orders file is generated by server using the input allocations
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <OrdersTable data={previewOrders} />
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment >
  );
}