import axios from "axios";
import Cookies from "js-cookie";
import { Dispatch } from "react";
import { AppAction } from "../Context";
import { newLogoutAction } from "./Action";
import { Message } from "./Data";

export const checkIsLogin = function (): boolean {
    // Check the token exists in cookies
    if (Cookies.get("csrf_access_token")) {
        return true;
    } else {
        return false;
    }
};

export const axiosErrorHandler = function (error: any, dispatch: Dispatch<AppAction>) {
    if (axios.isAxiosError(error)) {
        // JWT unauthorized access
        if (error.response?.status === 401) {
            const msg = (error.response.data as Message).msg;
            dispatch(newLogoutAction(msg));
        }
    }
    console.error(error);
};