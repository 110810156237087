import * as React from 'react';
import { Order, SimulationResult } from '../Utils/Data';
import MUIDataTable from 'mui-datatables';
import Title from './Title';
import { ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabPanel } from './TabPanel';


interface OrdersTableProps {
  data: Order[];
}

export default function OrdersTable({ data }: OrdersTableProps) {
  return (
    <React.Fragment>
      <ResponsiveContainer >
      <MUIDataTable
        title={<Title>Orders Table</Title>}
        data={data}
        columns={["Date", "Symbol", "Order", "Shares"]}
        options={{
          elevation: 0,
          filter: false,
          print: false,
          selectableRows: "none",
          downloadOptions: { filename: 'orders.csv', separator: ',' },
        }}
      />
      </ResponsiveContainer>
    </React.Fragment>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function ResultOrdersTable({ data }: { data: SimulationResult }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="construct portfolio">
          <Tab label="Strategy" {...a11yProps(0)} />
          <Tab label="Benchmark" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OrdersTable data={data.strategy.orders} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrdersTable data={data.benchmark.orders} />
      </TabPanel>
    </React.Fragment >
  );
}