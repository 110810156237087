import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface Props {
  title: string;
  strategyStats: string;
  benchmarkStats: string;
  asOfDate: string;
}

export default function StatsBox({ title, strategyStats, benchmarkStats, asOfDate }: Props) {
  return (
    <React.Fragment>
      <Typography component="p" variant="h6" color="primary">
        {title}
      </Typography>
      <Typography color="text.secondary">
        as of {asOfDate}
      </Typography>
      <Divider />
      <Typography component="p" variant="subtitle1" paddingTop={1}>
        Strategy
      </Typography>
      <Typography component="p" variant="h5">
        {strategyStats}
      </Typography>
      <Typography component="p" variant="subtitle1">
        Benchmark
      </Typography>
      <Typography component="p" variant="h5">
        {benchmarkStats}
      </Typography>
    </React.Fragment>
  );
}