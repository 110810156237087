import axios from "axios";
import Cookies from "js-cookie";

// Using method to create axios instance for cookies update
const createAxiosInstance = (baseURL: string, includeTokenHeader: boolean = true) => () => {
    if (includeTokenHeader) {
        return axios.create({
            baseURL: baseURL,
            headers: {
                "Content-type": "application/json",
                "X-CSRF-TOKEN": Cookies.get("csrf_access_token")!,
            },
            withCredentials: true,
        });
    } else {
        return axios.create({
            baseURL: baseURL,
            headers: {
                "Content-type": "application/json",
            },
            withCredentials: true,
        });
    }
}

export const ApiClient = createAxiosInstance("/api");

export const AuthClient = createAxiosInstance("/auth", false);