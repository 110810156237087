import * as React from 'react';
import { SimulationFormProps } from './SimulationPage';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import UploadOrdersForm from './UploadOrdersForm';
import SymbolAllocationForm from './SymbolAllocationForm';
import { TabPanel } from '../Components/TabPanel';


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ConstructPortfolioForm({ simulation, onChange }: SimulationFormProps) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    // Choose coresponding tab
    if (simulation.orders) {
      setValue(1);
    } else {
      setValue(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      // Remove orders
      if (simulation.orders) {
        onChange({ orders: undefined });
      }
    } else {
      // Remove allocation
      if (simulation.allocation) {
        onChange({ allocation: undefined });
      }
    }
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="construct portfolio">
          <Tab label="Auto Rebalance" {...a11yProps(0)} />
          <Tab label="Order File" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SymbolAllocationForm simulation={simulation} onChange={onChange} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UploadOrdersForm simulation={simulation} onChange={onChange} />
      </TabPanel>
    </React.Fragment >
  );
}