import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import { Simulation } from '../Utils/Data';

interface ReviewFormProps {
  simulation: Partial<Simulation>;
}

export default function ReviewForm({ simulation }: ReviewFormProps) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom paddingBottom={2}>
        Simulation Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="start_date"
            name="start_date"
            label="Start Date"
            fullWidth
            defaultValue={simulation.start_date}
            type="date"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="end_date"
            name="end_date"
            label="End Date"
            fullWidth
            defaultValue={simulation.end_date}
            type="date"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            id="commission"
            name="commission"
            label="Commission"
            fullWidth
            defaultValue={simulation.commission}
            isNumericString
            customInput={TextField}
            helperText="The commission fee for each order"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            id="impact"
            name="impact"
            label="Impact"
            fullWidth
            defaultValue={simulation.impact}
            isNumericString
            customInput={TextField}
            helperText="The market impact loss in percentage for each order"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            id="start_value"
            name="start_value"
            label="Start Value"
            fullWidth
            defaultValue={simulation.start_value}
            decimalScale={0}
            isNumericString
            thousandSeparator
            prefix="$"
            customInput={TextField}
            helperText="The start cash value for simulation"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {simulation.orders && (<TextField
            required
            id="file"
            name="file"
            label="Orders File"
            fullWidth
            defaultValue={(simulation.orders && simulation.orders.length > 0) ? "Passed" : "Failed"}
            helperText="The uploaded orders file status"
            disabled
          />)}
          {simulation.allocation && (<TextField
            required
            id="alloc"
            name="alloc"
            label="Symbol Allocation"
            fullWidth
            defaultValue={(simulation.allocation && simulation.allocation.size > 0) ? "Passed" : "Failed"}
            helperText="The portfolio symbol allocation"
            disabled
          />)}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}