import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { SimulationFormProps } from './SimulationPage';
import { SIMULATION_END_DATE, SIMULATION_START_DATE } from '../Utils/Constants';

export default function SimulationParamForm({ onChange, simulation }: SimulationFormProps) {
  const [startError, setStartError] = React.useState("");
  const [endError, setEndError] = React.useState("");

  const handleChangeDate = (name: string) => (e: React.ChangeEvent<HTMLInputElement>): void => {
    let error = ""
    if (e.target.value < SIMULATION_START_DATE || e.target.value > SIMULATION_END_DATE) {
      error = "The input date is out of range";
    }
    switch (name) {
      case "start_date":
        onChange({ start_date: e.target.value });
        setStartError(error);
        break;
      case "end_date":
        onChange({ end_date: e.target.value });
        setEndError(error);
        break;
      default:
        throw new Error("invalid field name");
    }
  };

  const handleValueChange = (name: string) => (values: NumberFormatValues) => {
    switch (name) {
      case "commission":
        onChange({ commission: Number(values.value) });
        break;
      case "impact":
        onChange({ impact: Number(values.value) });
        break;
      case "start_value":
        onChange({ start_value: Number(values.value) });
        break;
      default:
        throw new Error("invalid field name");
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom paddingBottom={2}>
        Simulation Parameters
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="start_date"
            name="start_date"
            label="Start Date"
            fullWidth
            defaultValue={SIMULATION_START_DATE}
            type="date"
            inputProps={{ 
              min: SIMULATION_START_DATE, 
              max: SIMULATION_END_DATE,
            }}
            onChange={handleChangeDate("start_date")}
            error={startError? true: false}
            helperText={startError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="end_date"
            name="end_date"
            label="End Date"
            fullWidth
            defaultValue={SIMULATION_END_DATE}
            type="date"
            inputProps={{ 
              min: SIMULATION_START_DATE, 
              max: SIMULATION_END_DATE,
            }}
            onChange={handleChangeDate("end_date")}
            error={endError? true: false}
            helperText={endError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            id="commission"
            name="commission"
            label="Commission"
            fullWidth
            defaultValue={simulation.commission}
            isNumericString
            customInput={TextField}
            helperText="The commission fee for each order"
            onValueChange={handleValueChange("commission")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            id="impact"
            name="impact"
            label="Impact"
            fullWidth
            defaultValue={simulation.impact}
            isNumericString
            customInput={TextField}
            helperText="The market impact loss in percentage for each order"
            onValueChange={handleValueChange("impact")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            id="start_value"
            name="start_value"
            label="Start Value"
            fullWidth
            defaultValue={simulation.start_value}
            decimalScale={0}
            isNumericString
            thousandSeparator
            prefix="$"
            customInput={TextField}
            helperText="The start cash value for simulation"
            onValueChange={handleValueChange("start_value")}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}