import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InsightsIcon from '@mui/icons-material/Insights';
import BarChartIcon from '@mui/icons-material/BarChart';
import HiveIcon from '@mui/icons-material/Hive';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Context from '../Context';
import AuthService from '../Services/Auth'
import { axiosErrorHandler } from '../Utils/Service';
import { newLogoutAction } from '../Utils/Action';

const mainListItems = (
  <List>
    {/* Simulation Page */}
    <ListItem button component={Link} to="/simulation/">
      <ListItemIcon>
        <InsightsIcon />
      </ListItemIcon>
      <ListItemText primary="Simulation" />
    </ListItem>
    {/* Result Page */}
    <ListItem button component={Link} to="/result/">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Result" />
    </ListItem>
    {/* Market Data Page */}
    <ListItem button component={Link} to="/marketdata/">
      <ListItemIcon>
        <HiveIcon />
      </ListItemIcon>
      <ListItemText primary="Market Data" />
    </ListItem>
  </List>
);

const LogoutButton = function () {
  const { dispatch } = React.useContext(Context);

  const handleClick = () => {
    // send logout request to server
    const logout = async () => {
      try {
        await AuthService.logout();
        dispatch(newLogoutAction());
      } catch (error) {
        axiosErrorHandler(error, dispatch);
      }
    };
    logout();
  };

  return (
    <React.Fragment>
      <List>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </React.Fragment>
  );
}

export default function Menus() {
  const { isLogin } = React.useContext(Context);

  return (
    <React.Fragment>
      {mainListItems}
      <Divider />
      {isLogin && (
        <LogoutButton />
      )}
    </React.Fragment>
  );
}