import { ApiClient } from "../Utils/Http";
import { CheckSymbol, MarketDataInfo, Message } from "../Utils/Data";

class MarketDataService {
    getMarketDataInfo() {
        return ApiClient().get<MarketDataInfo>("/marketdata");
    }

    downloadMarketData(symbols: string[]) {
        return ApiClient().post<Message>("/marketdata", { symbols });
    }

    checkSymbol(symbol: string) {
        return ApiClient().get<CheckSymbol>("/marketdata/" + symbol);
    }
}

export default new MarketDataService();