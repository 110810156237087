import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MarketdataService from '../Services/MarketData';
import Context from '../Context';
import { useRefreshMarketDataInfo, useSigninPageRedirect } from '../hooks';
import { axiosErrorHandler } from '../Utils/Service';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';

interface SymbolSearchData {
  candidate: string;
  error?: string;
}

interface SymbolSearchProps {
  symbolList: string[];
  setSymbolList: React.Dispatch<React.SetStateAction<string[]>>;
}

function SymbolSearch({ symbolList, setSymbolList }: SymbolSearchProps) {
  const { marketDataInfo, dispatch, } = React.useContext(Context);
  const [data, setData] = React.useState<SymbolSearchData>({
    candidate: "",
    error: undefined,
  });
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const isValidSymbol = React.useCallback(async (symbol: string) => {
    try {
      setLoading(true);
      const response = await MarketdataService.checkSymbol(symbol);
      return response.data.is_valid;
    } catch (error) {
      axiosErrorHandler(error, dispatch);
      setData({
        ...data,
        error: "Failed to check symbol from server.",
      });
      return false;
    } finally {
      setLoading(false);
      inputRef.current?.focus();
    }
  }, [dispatch, data]);

  const validateSymbol = React.useCallback(async (symbol: string) => {
    // Check already in candidate list or not
    if (symbolList.includes(symbol)) {
      setData({
        ...data,
        error: symbol + " has already been added.",
      });
      return;
    }
    // Check already added before
    const addedSymbols = marketDataInfo.map(info => info.symbol)
    if (addedSymbols.includes(symbol)) {
      setData({
        ...data,
        error: symbol + " has already been added.",
      });
      return;
    }
    // Validate symbol using server
    const valid = await isValidSymbol(symbol);
    if (valid) {
      setSymbolList([...symbolList, symbol]);
      setData({
        candidate: "",
        error: undefined,
      });
    } else {
      setData({
        ...data,
        error: symbol + " is not a Yahoo! finance symbol.",
      });
    }
  }, [data, marketDataInfo, symbolList, isValidSymbol, setSymbolList]);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      candidate: evt.target.value,
      error: undefined,
    });
  };

  const handleKeyDown = React.useCallback((evt: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var searchSymbol = data.candidate.trim().toUpperCase();
      if (searchSymbol) {
        validateSymbol(searchSymbol);
      }
    }
  }, [data, validateSymbol]
  );

  const handleDelete = (deleteIndex: number) => () => {
    const newList = symbolList.filter((_, idx) => idx !== deleteIndex);
    setSymbolList(newList);
  };

  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body1" gutterBottom>
        Please enter the <Link href="https://finance.yahoo.com/lookup/" variant="body1">Yahoo! Finance</Link> symbol
      </Typography>
      <TextField
        id="symbol-search"
        placeholder="Type or paste symbol and press `Enter`"
        variant="outlined"
        disabled={loading}
        error={data.error ? true : false}
        helperText={data.error ? data.error : ""}
        value={data.candidate}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading && <CircularProgress />}
            </InputAdornment>
          )
        }}
      />
      <Paper elevation={0} sx={{ p: 1 }}>
        {symbolList.map((symbol, idx) => <Chip key={symbol} label={symbol} sx={{ m: 0.3 }} onDelete={handleDelete(idx)} />)}
      </Paper>
    </Paper>
  );
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name,
  };
}

export default function MarketDataPage() {
  useSigninPageRedirect();
  const { loading, refresh } = useRefreshMarketDataInfo();
  const {
    marketDataInfo,
    isLogin,
    dispatch
  } = React.useContext(Context);
  const [needDownloadMD, setNeedDownloadMD] = React.useState(false);
  const [symbolList, setSymbolList] = React.useState<Array<string>>([]);

  // Trigger download market data request in SymbolSearch
  const handleDownload = () => {
    setNeedDownloadMD(true);
  };

  // This refresh market data info effect will execute only once when page load nad is login
  React.useEffect(() => {
    if (isLogin) {
      refresh();
    }
  }, [isLogin, refresh]);

  // Listen to download market data signal and send out request
  React.useEffect(() => {
    if (needDownloadMD && symbolList.length > 0) {
      const sendDownload = async () => {
        try {
          await MarketdataService.downloadMarketData(symbolList);
          // Clean up candidate list
          setSymbolList([]);
          // Turn off the triggering flag and refresh market data
          setNeedDownloadMD(false);
          refresh();
        } catch (error) {
          axiosErrorHandler(error, dispatch);
        }
      };
      sendDownload();
    }
  }, [needDownloadMD, symbolList, refresh, dispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" component="div">
                <Box fontWeight='fontWeightMedium' display='inline'>
                  Market Data Management
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={needDownloadMD || symbolList.length === 0}
                variant="contained"
                onClick={handleDownload}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SymbolSearch symbolList={symbolList} setSymbolList={setSymbolList} />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Symbol</TableCell>
                  <TableCell align="left">ShortName</TableCell>
                  <TableCell align="left">Sector</TableCell>
                  <TableCell align="left">Industry</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && marketDataInfo.map((row) => (
                  <TableRow
                    key={"row-" + row.symbol}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Paper component={Stack} direction="row" elevation={0}>
                        {row.logo_url ? <Avatar src={row.logo_url} /> : <Avatar {...stringAvatar(row.symbol)} />}
                        <Paper component={Stack} direction="column" justifyContent="center" elevation={0} paddingLeft={1}>
                          <Typography variant="body1">{row.symbol}</Typography>
                        </Paper>
                      </Paper>
                    </TableCell>
                    <TableCell align="left">{row.shortName}</TableCell>
                    <TableCell align="left">{row.sector}</TableCell>
                    <TableCell align="left">{row.industry}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {loading && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}