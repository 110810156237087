import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SimulationParamForm from './SimulationParamForm';
import ReviewForm from './ReviewForm';
import { useSigninPageRedirect } from '../hooks';
import Container from '@mui/material/Container';
import { Simulation, completeSimulationParam, validateAllocation } from '../Utils/Data';
import simulationService from '../Services/Simulation';
import { axiosErrorHandler } from '../Utils/Service';
import Context from '../Context';
import LinearProgress from '@mui/material/LinearProgress';
import { newUpdateSimulationParamAction, newUpdateSimulationResultAction } from '../Utils/Action';
import { useNavigate } from 'react-router-dom';
import { SIMULATION_END_DATE, SIMULATION_START_DATE } from '../Utils/Constants';
import ConstructPortfolioForm from './ConstructPortfolioForm';


function useSaveSimulationHook() {
  const { simulationResult, simulationParam } = React.useContext(Context);

  // Storing data into local storage
  React.useEffect(() => {
    if (simulationResult) {
      localStorage.setItem("simulationResult", JSON.stringify(simulationResult));
    }
    if (simulationParam) {
      localStorage.setItem("simulationParam", JSON.stringify(simulationParam));
    }
  }, [simulationResult, simulationParam]);
}

const steps = ['Enter the simulation parameters', 'Construct a portfolio', 'Review your simulation'];

function getStepContent(step: number, simulation: Partial<Simulation>, onChange: (partialSim: Partial<Simulation>) => void) {
  switch (step) {
    case 0:
      return <SimulationParamForm simulation={simulation} onChange={onChange} />;
    case 1:
      return <ConstructPortfolioForm simulation={simulation} onChange={onChange} />;
    case 2:
      return <ReviewForm simulation={simulation} />;
    default:
      throw new Error('Unknown step');
  }
}

export interface SimulationFormProps {
  onChange: (partialSim: Partial<Simulation>) => void;
  simulation: Partial<Simulation>;
}

export default function SimulationPage() {
  useSigninPageRedirect();
  useSaveSimulationHook();

  const [disableButton, setDisableButton] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [simulation, setSimulation] = React.useState<Partial<Simulation>>({
    start_date: SIMULATION_START_DATE,
    end_date: SIMULATION_END_DATE,
    commission: 0.0,
    impact: 0.0,
    start_value: 100000,
  });
  const { dispatch } = React.useContext(Context);
  let navigate = useNavigate();

  const runSimulation = React.useCallback(async () => {
    try {
      const param = completeSimulationParam(simulation);
      const result = await simulationService.runSimulation(param);
      // Saved the latest simulation param and result locally
      dispatch(newUpdateSimulationParamAction(param));
      dispatch(newUpdateSimulationResultAction(result.data));
      navigate("/result/");
    } catch (error) {
      axiosErrorHandler(error, dispatch);
    }
  }, [simulation, dispatch, navigate]);

  // Send out run simulation request once we hit the run simulation button
  React.useEffect(() => {
    if (activeStep === steps.length) {
      runSimulation();
    }
  }, [activeStep, runSimulation]);

  // Control whether we can press next button
  React.useEffect(() => {
    switch (activeStep) {
      // SimulationParamForm
      case 0:
        if (simulation.start_date! <= simulation.end_date! &&
          simulation.start_date! >= SIMULATION_START_DATE && simulation.start_date! <= SIMULATION_END_DATE &&
          simulation.end_date! >= SIMULATION_START_DATE && simulation.end_date! <= SIMULATION_END_DATE) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
        break;
      // UploadOrdersForm
      case 1:
        if (simulation.orders && simulation.orders.length > 0) {
          setDisableButton(false);
        } else if (simulation.allocation) {
          const valid = validateAllocation(simulation.allocation);
          setDisableButton(!valid);
        } else {
          setDisableButton(true);
        }
        break;
      // All other forms
      default:
        setDisableButton(false);
    }
  }, [activeStep, simulation]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onChange = (partialSim: Partial<Simulation>) => {
    setSimulation({ ...simulation, ...partialSim });
  }

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          Create a market simulation
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Your simulation is submitted.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Please wait for server finish running your simulation.
              </Typography>
              <LinearProgress />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep, simulation, onChange)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                  disabled={disableButton}
                >
                  {activeStep === steps.length - 1 ? 'Run Simulation' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </Container>
  );
}