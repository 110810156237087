import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PortValChart from '../Components/PortValChart';
import StatsBox from '../Components/StatsBox';
import PortValTable from '../Components/PortValTable';
import { useSigninPageRedirect } from '../hooks';
import Context from '../Context';
import { formatMoney, formatNumber, SimulationResult } from '../Utils/Data';
import { newUpdateSimulationResultAction } from '../Utils/Action';
import { ResultOrdersTable } from '../Components/OrdersTable';
import Tabs from '@mui/material/Tabs';
import { TabPanel } from '../Components/TabPanel';
import Tab from '@mui/material/Tab';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


// JSON and local storage related logic
interface SavedResultEntry {
  key: string;
  value: SimulationResult;
};

function loadResultFromLocalStorage() {
  let obj = localStorage.getItem("simulationResult");
  if (obj) {
    let data: SimulationResult = JSON.parse(obj);
    return data;
  }
}

function loadSavedResultFromLocalStorage() {
  let obj = localStorage.getItem("savedSimulationResult");
  if (obj) {
    let data: Array<SavedResultEntry> = JSON.parse(obj);
    let mapData = new Map<string, SimulationResult>();
    data.forEach(d => mapData.set(d.key, d.value));
    return mapData;
  } else {
    return new Map<string, SimulationResult>();
  }
}

function saveSavedResultToLocalStorage(data: Map<string, SimulationResult>) {
  let arr = Array.from(data.entries()).map<SavedResultEntry>(v => ({key: v[0], value: v[1]}));
  localStorage.setItem("savedSimulationResult", JSON.stringify(arr));
}

interface RowPaperProps {
  fixedHeight?: number;
  children?: React.ReactNode;
}

function RowPaper({ fixedHeight, children }: RowPaperProps) {
  const rowStyle = () => {
    const style = {
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      ...fixedHeight ? { height: fixedHeight } : {}
    }
    return style;
  };

  return (
    <Paper sx={rowStyle()}>
      {children}
    </Paper>
  );
}

interface ResultTabProps {
  result?: SimulationResult;
}

function ResultTab(props: ResultTabProps) {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {props.result ? (
          <>
            {/* Portfolio Stats */}
            {props.result.strategy.port_val !== undefined && (<Grid item xs={12} md={4} lg={3}>
              <RowPaper fixedHeight={240}>
                <StatsBox
                  title="Portfolio Value"
                  strategyStats={formatMoney(props.result.strategy.port_val)}
                  benchmarkStats={formatMoney(props.result.benchmark.port_val)}
                  asOfDate={props.result.end_date}
                />
              </RowPaper>
            </Grid>)}
            {props.result.strategy.returns !== undefined && (<Grid item xs={12} md={4} lg={3}>
              <RowPaper fixedHeight={240}>
                <StatsBox
                  title="Returns"
                  strategyStats={formatNumber(props.result.strategy.returns, 2, '%')}
                  benchmarkStats={formatNumber(props.result.benchmark.returns, 2, '%')}
                  asOfDate={props.result.end_date}
                />
              </RowPaper>
            </Grid>)}
            {props.result.strategy.sharpe_ratio !== undefined && (<Grid item xs={12} md={4} lg={3}>
              <RowPaper fixedHeight={240}>
                <StatsBox
                  title="Sharpe Ratio"
                  strategyStats={formatNumber(props.result.strategy.sharpe_ratio, 2)}
                  benchmarkStats={formatNumber(props.result.benchmark.sharpe_ratio, 2)}
                  asOfDate={props.result.end_date}
                />
              </RowPaper>
            </Grid>)}
            {props.result.strategy.volatility !== undefined && (<Grid item xs={12} md={4} lg={3}>
              <RowPaper fixedHeight={240}>
                <StatsBox
                  title="Volatility"
                  strategyStats={formatNumber(props.result.strategy.volatility, 3)}
                  benchmarkStats={formatNumber(props.result.benchmark.volatility, 3)}
                  asOfDate={props.result.end_date}
                />
              </RowPaper>
            </Grid>)}
            {props.result.strategy.mdd !== undefined && (<Grid item xs={12} md={4} lg={3}>
              <RowPaper fixedHeight={240}>
                <StatsBox
                  title="Maximum Drawdown"
                  strategyStats={formatNumber(props.result.strategy.mdd * 100, 2, '%')}
                  benchmarkStats={formatNumber(props.result.benchmark.mdd * 100, 2, '%')}
                  asOfDate={props.result.end_date}
                />
              </RowPaper>
            </Grid>)}
            {/* PortVals Chart */}
            <Grid item xs={12}>
              <RowPaper fixedHeight={480}>
                <PortValChart data={props.result} />
              </RowPaper>
            </Grid>
            {/* PortVals Table */}
            <Grid item xs={12}>
              <RowPaper>
                <PortValTable data={props.result} />
              </RowPaper>
            </Grid>
            {props.result.strategy.orders !== undefined && props.result.benchmark.orders !== undefined && (
            <Grid item xs={12}>
              <RowPaper>
                <ResultOrdersTable data={props.result} />
              </RowPaper>
            </Grid>)}
          </>
        ) : (
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" align="center">No simulation result</Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

function ChooseResultTab() {
  const [savedResult, setSavedResult] = React.useState<Map<string, SimulationResult>>(new Map<string, SimulationResult>());
  const [chooseKey, setChooseKey] = React.useState("");

  React.useEffect(() => {
    if (chooseKey === "") {
      const data = loadSavedResultFromLocalStorage();
      setSavedResult(data);
    }
  }, [chooseKey]);

  const handleChange = (event: SelectChangeEvent) => {
    setChooseKey(event.target.value as string);
  };

  const handleDelete = React.useCallback(() => {
    savedResult.delete(chooseKey);
    saveSavedResultToLocalStorage(savedResult);
    setChooseKey("");
  }, [chooseKey, savedResult]);

  return (
    <Grid container spacing={3}>
      {savedResult.size > 0 ? (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Saved key</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chooseKey}
                label="SavedKey"
                onChange={handleChange}
              >
                {Array.from(savedResult.keys()).map(k => <MenuItem key={k} value={k}>{k}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          {chooseKey !== "" && (<Grid item xs={12}>
            <Button variant='contained' onClick={handleDelete}>Delete</Button>
          </Grid>)}
          <Grid item xs={12}>
            <ResultTab result={savedResult.get(chooseKey)} />
          </Grid>
        </>
       ) : (
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" align="center">No saved simulation result</Typography>
        </Grid>
       )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SaveSimulationResultDialog(props: ResultTabProps) {
  const {result} = props;
  const [open, setOpen] = React.useState(false);
  const [saveKey, setSaveKey] = React.useState("");

  const handleSetSaveKey = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSaveKey(e.target.value);
  };

  const handleSaveResult = React.useCallback(() => {
    if (result) {
      const data = loadSavedResultFromLocalStorage();
      data.set(saveKey, result);
      saveSavedResultToLocalStorage(data);
      handleClose();
    }
  }, [saveKey, result]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Save Simulation Result
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save Simulation Result</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a key to save the result
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="input-save-key"
            name="save_key"
            label="Save Key"
            onChange={handleSetSaveKey}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={saveKey.length === 0 || result === undefined}
            variant="contained"
            onClick={handleSaveResult}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function ResultPage() {
  useSigninPageRedirect();

  const { simulationResult: result, isLogin, dispatch } = React.useContext(Context);
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (isLogin && result === undefined) {
      const saved = loadResultFromLocalStorage();
      if (saved) {
        dispatch(newUpdateSimulationResultAction(saved));
      }
    }
  }, [isLogin, result, dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="choose simulation result">
          <Tab label="Recent Result" {...a11yProps(0)} />
          <Tab label="Saved Result" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item>
            <SaveSimulationResultDialog result={result} />
          </Grid>
          <Grid item>
            <ResultTab result={result} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChooseResultTab />
      </TabPanel>
    </React.Fragment >
  );
}