import { AuthClient } from "../Utils/Http";

class AuthService {
    login(username: string, password: string) {
        return AuthClient().post<any>("/login", { username, password });
    }

    logout() {
        return AuthClient().post<any>("/logout");
    }
}

export default new AuthService();