import * as React from "react";
import { useNavigate } from "react-router-dom";
import Context from "./Context";
import MarketdataService from './Services/MarketData';
import { newLoginAction, newSetStateAction } from "./Utils/Action";
import { axiosErrorHandler, checkIsLogin } from "./Utils/Service";

export function useSigninPageRedirect() {
  const { isLogin, dispatch } = React.useContext(Context);
  let navigate = useNavigate();

  // Top-level logic for checking login status and redirect to signin page
  React.useEffect(() => {
    if (!isLogin) {
      if (checkIsLogin()) {
        dispatch(newLoginAction());
      } else {
        navigate("/signin/");
      }
    }
  }, [isLogin, dispatch, navigate]);
}

export function useRefreshMarketDataInfo() {
  const { dispatch } = React.useContext(Context);
  const [loading, setLoading] = React.useState(false);
  const [needRefresh, setNeedRefresh] = React.useState(false);

  const refresh = React.useCallback(() => {
    setNeedRefresh(true);
  }, []);

  const getMarketDataInfo = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await MarketdataService.getMarketDataInfo();
      return response.data.data;
    } catch (error) {
      axiosErrorHandler(error, dispatch);
      return [];
    } finally {
      setLoading(false);
      setNeedRefresh(false);
    }
  }, [dispatch]);

  // Control when to send refresh request
  React.useEffect(() => {
    if (needRefresh) {
      const refreshInfo = async () => {
        const info = await getMarketDataInfo();
        // Update market data info and turn off trigger flag
        dispatch(newSetStateAction({ marketDataInfo: info }));
      };
      refreshInfo();
    }
  }, [needRefresh, getMarketDataInfo, dispatch]);

  return { loading, refresh };
}