import * as React from 'react';
import { formatMoney, SimulationResult } from '../Utils/Data';
import MUIDataTable from 'mui-datatables';
import Title from './Title';
import { ResponsiveContainer } from 'recharts';

function provideData(data: SimulationResult) {
  return data.strategy.data.map((row, idx) => {
    return { 
      Date: row.Date, 
      Strategy: formatMoney(row.PortVals),
      Benchmark: formatMoney(data.benchmark.data[idx].PortVals) 
    };
  });
}

interface PortValTableProps {
  data: SimulationResult;
}

export default function PortValTable({ data }: PortValTableProps) {
  return (
    <React.Fragment>
      <ResponsiveContainer >
      <MUIDataTable
        title={<Title>PortVals Table</Title>}
        data={provideData(data)}
        columns={["Date", "Strategy", "Benchmark"]}
        options={{
          elevation: 0,
          filter: false,
          print: false,
          selectableRows: "none",
          downloadOptions: { filename: 'portvals.csv', separator: ',' },
        }}
      />
      </ResponsiveContainer>
    </React.Fragment>
  );
}