import { AppAction, AppState } from "../Context";
import { Simulation, SimulationResult } from "./Data";

export function newSetStateAction(state: Partial<AppState>): AppAction {
  return { type: "SET_STATE", state: state };
};

export function newLoginAction(): AppAction {
  return { type: "LOG_IN" };
};

export function newLogoutAction(message?: string): AppAction {
  return { type: "LOG_OUT", message: message };
};

export function newUpdateSimulationResultAction(result: SimulationResult): AppAction {
  return { type: "SET_STATE", state: { simulationResult: result } }
}

export function newUpdateSimulationParamAction(param: Simulation): AppAction {
  return { type: "SET_STATE", state: { simulationParam: param } }
}