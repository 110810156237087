import { createContext, useReducer, Dispatch, ReactNode } from "react";
import Cookies from "js-cookie";
import { MarketDataItem, Simulation, SimulationResult } from "../Utils/Data";

// Defining action
type SetStateAction = {
  type: "SET_STATE";
  state: Partial<AppState>;
};

type LoginAction = {
  type: "LOG_IN";
};

type LogoutAction = {
  type: "LOG_OUT";
  message?: string;
};

export type AppAction =
  | SetStateAction
  | LoginAction
  | LogoutAction;

// Defining state
export interface AppState {
  isLogin: boolean;
  loginErrorMessage?: string;
  loginRedirectToPrevious: boolean;
  marketDataInfo: MarketDataItem[];
  simulationResult?: SimulationResult;
  simulationParam?: Simulation;
}

const initialState: AppState = {
  isLogin: false,
  loginErrorMessage: undefined,
  loginRedirectToPrevious: false,
  marketDataInfo: [],
  simulationResult: undefined,
  simulationParam: undefined,
};

// Create context
interface AppContext extends AppState {
  dispatch: Dispatch<AppAction>;
}

const Context = createContext<AppContext>(
  initialState as AppContext
);

// Create provider
const { Provider } = Context;

export const AppProvider: React.FC<{ children: ReactNode }> = (props) => {
  const reducer = (
    state: AppState,
    action: AppAction
  ): AppState => {
    switch (action.type) {
      case "SET_STATE":
        return { ...state, ...action.state };
      case "LOG_IN":
        return { 
          ...state, 
          isLogin: true,
          loginErrorMessage: undefined 
        };
      case "LOG_OUT":
        // Reset to clean state
        Cookies.remove("csrf_access_token");
        return { 
          ...initialState, marketDataInfo: state.marketDataInfo, 
          isLogin: false, 
          loginRedirectToPrevious: true, 
          loginErrorMessage: action.message 
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ ...state, dispatch }}>{props.children}</Provider>;
};

export default Context;