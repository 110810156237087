import { ApiClient } from "../Utils/Http";
import { SimulationResult, Simulation, Order, OptimizePortfolioParam, StringNumberPair } from "../Utils/Data";

class SimulationService {
    runSimulation(simulation: Simulation) {
        // Map needs to convert to object in order to serialize to JSON
        let data: any = { simulation };
        if (simulation.allocation) {
            data["simulation"]["allocation"] = Object.fromEntries(simulation.allocation);
        }
        return ApiClient().post<SimulationResult>("/simulation", data);
    }

    previewAllocation(simulation: Simulation) {
        // Map needs to convert to object in order to serialize to JSON
        let data: any = { simulation };
        if (simulation.allocation) {
            data["simulation"]["allocation"] = Object.fromEntries(simulation.allocation);
        } else {
            throw new Error("Missing allocation in simulation");
        }
        return ApiClient().post<Array<Order>>("/simulation/allocation_preview", data);
    }

    optimizePortfolio(param: OptimizePortfolioParam) {
        let data: any = { param }
        return ApiClient().post<StringNumberPair[]>("/simulation/optimize_portfolio", data);
    }
}

export default new SimulationService();