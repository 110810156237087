import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Papa, { ParseResult } from 'papaparse';
import { isSide, Order } from '../Utils/Data';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, { StatusValue, IFileWithMeta } from 'react-dropzone-uploader'
import Context from '../Context';
import { SimulationFormProps } from './SimulationPage';

export default function UploadOrdersForm({ simulation, onChange }: SimulationFormProps) {
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const { marketDataInfo } = React.useContext(Context);

  // Show success message
  React.useEffect(() => {
    if (simulation.orders && simulation.orders.length > 0) {
      setSuccess(true);
      setMessage("Orders File Passed");
    }
  }, [simulation]);

  const handleRemove = () => {
    setMessage("");
    setSuccess(false);
    onChange({ orders: undefined });
  };

  const handleSuccess = (result: ParseResult<Order>, file: File) => {
    onChange({ orders: result.data });
  }

  const handleError = (err: { code: string, row: number, message: string }) => {
    setSuccess(false);
    setMessage(`[${err.code}] Row(${err.row}): ${err.message}`);
  };

  const handleComplete = (result: ParseResult<Order>, file: File) => {
    // Check error in results and show the first one
    if (result.errors.length > 0) {
      const err = result.errors[0];
      handleError(err);
      return;
    }
    if (result.data.length === 0) {
      handleError({ code: "DataError", row: 0, message: "File is empty." });
      return;
    }
    // Validate data in client side to reduce server loading
    const validSymbols = new Set(marketDataInfo.map(md => md.symbol));
    for (let i = 0; i < result.data.length; i++) {
      const row = result.data[i];
      // Not a valid date
      if (isNaN(Date.parse(row.Date))) {
        handleError({ code: "DataError", row: i, message: "Date is not a valid date." });
        return;
      }
      // Not a valid yyyy-MM-dd format
      if (!/^\d{4}-\d{2}-\d{2}$/.test(row.Date)) {
        handleError({ code: "DataError", row: i, message: "Date is not in yyyy-MM-dd format." });
        return;
      }
      // Missing market data
      if (!validSymbols.has(row.Symbol)) {
        handleError({ code: "DataError", row: i, message: "Please make sure all symbols are added in MarketData management." });
        return;
      }
      // Side not valid
      if (!isSide(row.Order)) {
        handleError({ code: "DataError", row: i, message: "Order should be either BUY or SELL." });
        return;
      }
      // Shares not a number
      if (isNaN(Number(row.Shares))) {
        handleError({ code: "DataError", row: i, message: "Shares is not a number." });
        return;
      }
    }
    // Save the valid data
    handleSuccess(result, file);
  };

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    // console.log(status, fileWithMeta.meta, fileWithMeta.file);
    switch (status) {
      case "done":
        Papa.parse<Order>(fileWithMeta.file, {
          header: true,
          skipEmptyLines: true,
          complete: handleComplete
        });
        break;
      case "removed":
        handleRemove();
        break;
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom paddingBottom={2}>
        Construct a portfolio with uploaded Orders CSV File
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dropzone
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            canCancel={false}
            inputContent="Drop CSV file here or click to upload."
            accept=".csv"
            styles={{
              dropzone: { overflow: "hidden" },
              preview: { borderBottom: "0px" },
            }}
          />
        </Grid>
        {message && (
          <Grid item xs={12}>
            <Typography variant="h6" color={success ? "green" : "error"} align="center">{message}</Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment >
  );
}